var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper bg-light-info auth-v1 px-2"
  }, [_c('div', {
    staticClass: "auth-inner py-2"
  }, [_c('b-card', {
    staticClass: "mb-0"
  }, [_c('b-link', {
    staticClass: "brand-logo",
    attrs: {
      "to": {
        name: 'auth-login'
      }
    }
  }, [_c('b-img', {
    attrs: {
      "src": _vm.appLogoFull,
      "height": "70"
    }
  })], 1), _c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('login.welcomeTo')) + " " + _vm._s(_vm.appName) + "! 👋 ")]), _c('b-card-text', {
    staticClass: "mb-2"
  }, [_vm._v(" Vui lòng điền thông tin để đăng ký tài khoản đại lý ")]), _c('validation-observer', {
    ref: "registerForm"
  }, [_c('b-form', {
    staticClass: "auth-register-form mt-2",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "name"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" Tên khách hàng "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    }])
  }, [_c('validation-provider', {
    attrs: {
      "name": "full name",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "state": errors.length > 0 ? false : null,
            "name": "register-name",
            "placeholder": "Hoang Trong Hieu"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label-for": "email"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" Email "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    }])
  }, [_c('validation-provider', {
    attrs: {
      "name": "Email",
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "email",
            "state": errors.length > 0 ? false : null,
            "name": "register-email",
            "placeholder": "john@example.com"
          },
          model: {
            value: _vm.regEmail,
            callback: function callback($$v) {
              _vm.regEmail = $$v;
            },
            expression: "regEmail"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label-for": "phone"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" Số điện thoại "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    }])
  }, [_c('validation-provider', {
    attrs: {
      "name": "phone",
      "rules": "required|phoneRule"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "phone",
            "state": errors.length > 0 ? false : null,
            "name": "register-name",
            "placeholder": "0942855550"
          },
          model: {
            value: _vm.phone,
            callback: function callback($$v) {
              _vm.phone = $$v;
            },
            expression: "phone"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Tên đại lý",
      "label-for": "agencyName"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "agencyName",
      "name": "register-name",
      "placeholder": ""
    },
    model: {
      value: _vm.agencyName,
      callback: function callback($$v) {
        _vm.agencyName = $$v;
      },
      expression: "agencyName"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Địa chỉ",
      "label-for": "agencyAddress"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "agencyAddress",
      "name": "register-address",
      "placeholder": ""
    },
    model: {
      value: _vm.agencyAddress,
      callback: function callback($$v) {
        _vm.agencyAddress = $$v;
      },
      expression: "agencyAddress"
    }
  })], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": "",
      "type": "submit"
    }
  }, [_vm._v(" Đăng ký ")])], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }