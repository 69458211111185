<template>
  <div class="auth-wrapper bg-light-info auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link
          :to="{ name: 'auth-login' }"
          class="brand-logo"
        >
          <b-img
            :src="appLogoFull"
            height="70"
          />
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('login.welcomeTo') }} {{ appName }}! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Vui lòng điền thông tin để đăng ký tài khoản đại lý
        </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >

            <!-- Full name -->
            <b-form-group label-for="name">
              <template #label>
                Tên khách hàng <span class="text-danger">*</span>
              </template>
              <validation-provider
                name="full name"
                rules="required"
              >
                <template #default="{ errors }">
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="errors.length > 0 ? false : null"
                    name="register-name"
                    placeholder="Hoang Trong Hieu"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </template>
              </validation-provider>
            </b-form-group>

            <!-- Email -->
            <b-form-group label-for="email">
              <template #label>
                Email <span class="text-danger">*</span>
              </template>
              <validation-provider
                name="Email"
                rules="required|email"
              >
                <template #default="{ errors }">
                  <b-form-input
                    id="email"
                    v-model="regEmail"
                    :state="errors.length > 0 ? false : null"
                    name="register-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </template>
              </validation-provider>
            </b-form-group>

            <!-- Phone -->
            <b-form-group label-for="phone">
              <template #label>
                Số điện thoại <span class="text-danger">*</span>
              </template>
              <validation-provider
                name="phone"
                rules="required|phoneRule"
              >
                <template #default="{ errors }">
                  <b-form-input
                    id="phone"
                    v-model="phone"
                    :state="errors.length > 0 ? false:null"
                    name="register-name"
                    placeholder="0942855550"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </template>
              </validation-provider>
            </b-form-group>

            <!-- Tax code -->

            <!-- Agency name -->
            <b-form-group
              label="Tên đại lý"
              label-for="agencyName"
            >
              <b-form-input
                id="agencyName"
                v-model="agencyName"
                name="register-name"
                placeholder=""
              />
            </b-form-group>

            <!-- Address - Sử dụng textarea cho trường này -->
            <b-form-group
              label="Địa chỉ"
              label-for="agencyAddress"
            >
              <b-form-textarea
                id="agencyAddress"
                v-model="agencyAddress"
                name="register-address"
                placeholder=""
              />
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Đăng ký
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm, BImg,
  BButton, BFormInput, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

import useJwtPublic from '@/api/auth/jwt/useJwtPublic'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { required, email } from '@validations'
import { $themeConfig } from '@themeConfig'

extend('phoneRule', {
  validate: value => /^[0-9]*$/.test(value),
  message: 'Please enter a valid phone number.',
})

const { appName, appLogoImage, appLogoFull } = $themeConfig.app

export default {
  components: {
    BCard,
    BImg,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',
      name: '',
      password: '',
      status: '',
      phone: '',
      agencyName: '',
      agencyAddress: '',
      required,
      email,
      appLogoFull,
      appLogoImage,
      appName,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwtPublic.register({
            name: this.name,
            phone: this.phone,
            email: this.regEmail,
            agencyName: this.agencyName,
            agencyAddress: this.agencyAddress,
          }).then(() => {
            this.$router.push('/login')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Reg Submitted', icon: 'EditIcon', variant: 'success',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
